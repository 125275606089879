import {get} from 'lodash';

export const getPromotionPrice = (totalCarts, promotion) => {
  const maxDiscount = get(promotion, 'promotionInfo.maxDiscount', 0);
  const percentage = get(promotion, 'promotionInfo.percentage', 0);
  const promotionPrice = Math.round((totalCarts * percentage) / 100);
  return promotionPrice >= maxDiscount ? maxDiscount : promotionPrice;
};

export default getPromotionPrice;
