import React, {useCallback, useEffect, useState} from 'react';
import {Input, Tag, Tooltip} from 'antd';
import {connect} from 'react-redux';
import {formatDate, toVND} from '~utils/helper';
import {getMembershipPrice, getTotalCarts} from '~utils/price-helper';
import PromotionIcon from '~components/icons/promotion-icon';
import CheckIcon from '~components/icons/check-icon';
import {get, post} from '~utils/api';
import MembershipIcon from '~components/icons/membership';
import X from '~components/icons/x';
import {CartTypes} from '~utils/enum';
import {getPromotionPrice} from '../constants';
import './styles.scss';

const {Search} = Input;

const PriceDetail = ({
  consumer,
  currentCarts,
  cartType,
  transport,
  promotion,
  isMemberShip,
  isAppliedFreeFirstOrder,
  isMembershipExpired,
  onPromotionChanged,
}) => {
  const [promotions, setPromotions] = useState([]);
  const [promotionCode, setPromotionCode] = useState('');
  const [promotionInvalid, setPromotionInvalid] = useState('');
  const [promotionSearching, setPromotionSearching] = useState(false);

  const getPromotion = useCallback(async () => {
    const res = await get(`/v1/promotion/consumer`);
    if (!res) {
      return;
    }
    setPromotions(res?.promotions ?? []);
  }, []);

  useEffect(() => {
    getPromotion();
  }, []);

  useEffect(() => {
    if (promotion) {
      setPromotionCode(promotion.promotionCode.code);
    }
  }, [promotion]);

  const getClaimPromotion = async (code, errMessage) => {
    try {
      setPromotionSearching(true);
      const res = await post(`/v1/promotion/consumer/claim`, {code});
      if (!res || res.promotionCode.isUsed) {
        setPromotionInvalid(errMessage);
        return;
      }

      setPromotionInvalid('');
      onPromotionChanged && onPromotionChanged(res);
    } finally {
      setPromotionSearching(false);
    }
  };

  const checkPromotion = async item => {
    setPromotionCode(item.publicCode);
    await getClaimPromotion(item.publicCode, 'Mã giảm giá hết hạn!');
  };

  const getMembershipValue = () => {
    return `${consumer.membership.name} (expired: ${formatDate(consumer.membership.expiredDate, 'MMM DD, yyyy')})`;
  };

  const getMembershipVoucher = () => {
    if (isMembershipExpired) return 'Gói thành viên đã hết hạn!!!';
    return isAppliedFreeFirstOrder
      ? 'Bạn đang áp dụng miễn phí đơn đầu tiên'
      : `Bạn đang áp dụng thuê ${consumer.membership.percentDiscount}% trên giá niêm yết`;
  };

  const getTotalPrice = () => {
    if (cartType === CartTypes.Rent && isMemberShip)
      return isAppliedFreeFirstOrder ? 0 : getMembershipPrice(currentCarts, consumer.membership);

    return getTotalCarts(currentCarts, cartType);
  };

  const getDiscountPrice = () => {
    const totalCarts = getTotalCarts(currentCarts, cartType);
    return toVND(0 - getPromotionPrice(totalCarts, promotion));
  };
  return (
    <div className='price-detail'>
      {isMemberShip ? (
        <div className='membership-section'>
          <div className='input-promotion'>
            <div className='title'>Gói thành viên</div>
            <div className='prom-input'>
              <Input
                size='large'
                prefix={<MembershipIcon />}
                suffix={!isMembershipExpired ? <CheckIcon /> : <X />}
                value={getMembershipValue()}
                readOnly
              />
            </div>
          </div>
          <div className='promotion-voucher'>
            <span className='promotion-voucher__content'>{getMembershipVoucher()}</span>
          </div>
        </div>
      ) : (
        <>
          <div className='price-row'>
            <div className='text-gray-3 text-13'>Mã giảm giá</div>
            <div className='promotions'>
              {promotions.length > 0 ? (
                <>
                  {promotions.map(x => (
                    <Tooltip key={x.id} title={x.name} onClick={() => checkPromotion(x)}>
                      <Tag color='blue'>{x.publicCode}</Tag>
                    </Tooltip>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className='input-promotion'>
            <div className='text-gray-3 text-13'>Hoặc nhập mã khuyến mãi</div>
            <div className='prom-input'>
              <Search
                size='large'
                prefix={<PromotionIcon />}
                suffix={promotion ? <CheckIcon /> : ''}
                value={promotionCode}
                onChange={e => {
                  setPromotionCode(e.target.value);
                  setPromotionInvalid('');
                }}
                onSearch={() => getClaimPromotion(promotionCode, 'Mã giảm giá không đúng hoặc hết hạn!')}
                loading={promotionSearching}
              />
            </div>
            {promotionInvalid && <div className='prom-invalid'>{promotionInvalid}</div>}
          </div>
        </>
      )}
      <div className='price-row'>
        <div className='text-gray-3 text-13'>Giá {cartType === CartTypes.Sale ? 'mua' : 'thuê'}</div>
        <div className='font-bold'>{toVND(getTotalPrice())}</div>
      </div>
      <div className='price-row'>
        <div className='text-gray-3 text-13'>Phí vận chuyển</div>
        <div className='font-bold'>{toVND(transport?.transportFee ?? 0)}</div>
      </div>
      <div className='price-row'>
        <div className='text-gray-3 text-13'>Giảm giá</div>
        <div className='font-bold'>{getDiscountPrice()}</div>
      </div>
      <div className='border-line my-16' />
    </div>
  );
};

export default connect(state => ({
  consumer: state.user.consumer,
}))(PriceDetail);
