import React from 'react';
import {Tag} from 'antd';
import {AddressType} from '~utils/enum';
import CheckIcon from '~components/icons/check-icon';

import './styles.scss';

const AddressItem = ({address, isCheck, onChangeAddress}) => {
  const getFullAddress = () => {
    return `${address.street ? `${address.street}, ` : ''}${address.ward}, ${address.district}, ${address.city}`;
  };

  return (
    <div className='address-item' onClick={onChangeAddress}>
      <div className='address-content'>
        <div className='title-header'>
          <div className='title'>
            <span className='username'>{address.name}</span>
            <span className='mx-10'>|</span>
            <span className='phone-number'>{address.mobileNo}</span>
          </div>
        </div>
        <div className='address-name'>{getFullAddress()}</div>
        <div className='tags'>
          {address.addressType &&
            Object.keys(AddressType).findIndex(x => AddressType[x][0] === address.addressType) > -1 && (
              <Tag className='uppercase'>
                {AddressType[Object.keys(AddressType).find(x => AddressType[x][0] === address.addressType)][1]}
              </Tag>
            )}
          {address.isDefault && (
            <Tag color='blue' className='uppercase'>
              Mặc định
            </Tag>
          )}
        </div>
      </div>
      {isCheck && (
        <div className='check-icon'>
          <CheckIcon size='24' />
        </div>
      )}
    </div>
  );
};

export default AddressItem;
