import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import * as addressActions from '~actions/address';
import ModalAddress from './modal-address';
import ModalAddressList from './modal-address-list';

import './styles.scss';

const Address = ({isGuest, addresses, address, getAddresses, onAddressChanged}) => {
  const [openAddressList, setOpenAddressList] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);

  useEffect(() => {
    if (isGuest) return;
    getAddresses();
  }, [isGuest]);

  useEffect(() => {
    if (!isGuest && addresses && addresses.length > 0) {
      onAddressChanged && onAddressChanged(addresses.find(x => x.isDefault) ?? addresses[0]);
    }
  }, [addresses]);

  const openDeliveryAddress = () => {
    if (isGuest) {
      setOpenAddress(true);
    } else {
      setOpenAddressList(true);
    }
  };

  const closeDeliveryAddress = () => {
    if (isGuest) {
      setOpenAddress(false);
    } else {
      setOpenAddressList(false);
    }
  };

  const handleChangeAddress = item => {
    onAddressChanged && onAddressChanged(item);
    closeDeliveryAddress();
  };

  return (
    <div className='address'>
      <div className='payment-title'>
        <div className='text-gray-3 text-13'>Giao tới</div>
        {address && (
          <div className='text-gray-3 text-12 cursor-pointer' onClick={openDeliveryAddress}>
            Thay đổi
          </div>
        )}
      </div>
      {address ? (
        <div className='mt-10 font-600'>
          <div>
            {address.name}
            <span className='mx-10'>|</span>
            {address.mobileNo}
          </div>
          <div>
            {address.street ? `${address.street}, ` : ''}
            {address.ward}, {address.district}
          </div>
          <div>{address.city}</div>
        </div>
      ) : (
        <div className='btn-secondary' onClick={openDeliveryAddress}>
          Thêm Địa Chỉ Giao Hàng
        </div>
      )}
      <div className='border-line my-16' />
      <ModalAddressList
        visible={openAddressList}
        onClose={closeDeliveryAddress}
        onReturn={handleChangeAddress}
        address={address}
      />
      <ModalAddress
        visible={openAddress}
        onReturn={handleChangeAddress}
        onClose={closeDeliveryAddress}
        address={address}
      />
    </div>
  );
};

export default connect(
  state => ({
    addresses: state.address.addresses,
    isGuest: state.user.isGuest,
    consumer: state.user.consumer,
  }),
  dispatch => ({
    getAddresses: () => dispatch(addressActions.getList()),
  })
)(Address);
