import React, {useCallback, useEffect, useState} from 'react';
import {Modal} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

import {CartTypes} from '~utils/enum';
import {get, post} from '~utils/api';
import Loading from '~components/page-loader';
import notification, {NotifyTypes} from '~utils/modals/notification';

import './styles.scss';

const defaultTransport = 'Giao hàng tỉnh';

const Transport = ({cartType, address, transport, onTransportChanged}) => {
  const [deliveries, setDeliveries] = useState([]);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [deliveryLoading, setDeliveryLoading] = useState(false);

  const getDeliveries = useCallback(async () => {
    const res = await get(`/v1/delivery`);
    if (!res) {
      return;
    }
    setDeliveries(res?.items ?? []);
  }, []);

  useEffect(() => {
    getDeliveries();
  }, []);

  useEffect(() => {
    if (address && deliveries?.length > 0 && !transport) {
      const delivery = deliveries?.find(x => x.name === defaultTransport);

      if (delivery) {
        // eslint-disable-next-line no-use-before-define
        handleChangeDelivery(delivery);
      }
    }
  }, [deliveries, address]);

  const openTransport = () => {
    setOpenDelivery(true);
  };

  const handleCloseDelivery = () => {
    setOpenDelivery(false);
  };

  const handleChangeDelivery = async item => {
    if (
      !address ||
      !address.name ||
      !address.street ||
      !address.wardId ||
      !address.districtId ||
      !address.cityId ||
      !address.mobileNo
    ) {
      notification(NotifyTypes.WARNING, {message: 'Vui lòng nhập đầy đủ thông tin địa chỉ giao hàng!'});
      return;
    }

    try {
      setDeliveryLoading(true);
      const res = await post('/v1/order/transport-info', {
        address: {
          name: address.name,
          street: address.street,
          wardId: address.wardId,
          districtId: address.districtId,
          cityId: address.cityId,
          mobileNo: address.mobileNo,
          ward: address.ward,
          district: address.district,
          city: address.city,
        },
        isSale: cartType === CartTypes.Sale,
        deliveryId: item.id,
      });
      if (!res) {
        return;
      }

      onTransportChanged && onTransportChanged({...res, id: item.id, deliveryName: item.name});
    } finally {
      setOpenDelivery(false);
      setDeliveryLoading(false);
    }
  };

  return (
    <div className='delivery'>
      <div className='payment-title'>
        <div className='text-gray-3 text-13'>Phương thức giao hàng</div>
        {transport && (
          <div className='text-gray-3 text-12 cursor-pointer' onClick={openTransport}>
            Thay đổi
          </div>
        )}
      </div>
      {transport ? (
        <div className='mt-10 font-600'>{transport.deliveryName}</div>
      ) : (
        <div className='btn-secondary' onClick={openTransport}>
          Chọn Phương Thức Giao Hàng
        </div>
      )}
      <div className='border-line' />
      <Modal
        visible={openDelivery}
        footer={null}
        centered
        title={
          <div className='payment-title'>
            <span className='text-black'>Chọn hình thức thanh toán</span>
            <span onClick={handleCloseDelivery} className='cursor-pointer'>
              <CloseOutlined style={{fontSize: 15}} />
            </span>
          </div>
        }
      >
        {deliveryLoading && <Loading />}
        <div className='drawer-change-delivery'>
          {deliveries.map(x => (
            <div className='delivery-item' key={x.id} onClick={() => handleChangeDelivery(x)}>
              {x.name}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Transport;
