import React from 'react';

const CheckIcon = ({fill = '#8DAFEF', size = '12'}) => (
  <svg width={size} height={size} viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.58541 6.41421L1.75699 3.58578L0.342773 5L4.58541 9.24264L11.6565 2.17157L10.2423 0.757355L4.58541 6.41421Z'
      fill={fill}
    />
  </svg>
);

export default CheckIcon;
