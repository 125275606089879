import React from 'react';
import {connect} from 'react-redux';
import {navigate} from 'gatsby';
import {CartTypes} from '~utils/enum';

import PaymentItem from '../payment-item';
import './styles.scss';

const Payment = ({currentCarts, isGuest, consumer, cartType}) => {
  const backToHome = () => {
    localStorage.removeItem('cartInfo');
    navigate('/home');
  };

  return (
    <div className='payment-list'>
      <div className='font-bold text-18'>Tất cả sản phẩm {cartType === CartTypes.Rent ? 'thuê' : 'mua'}</div>
      <div className='border-line my-20' />
      <div>
        {currentCarts.map(x => {
          return (
            <div key={isGuest ? x.cookieId : x.id}>
              <PaymentItem product={x} cartType={cartType} consumer={consumer} />
              <div className='border-line my-20' />
            </div>
          );
        })}
      </div>
      <div>
        <button className='btn-left' onClick={() => backToHome()}>
          Tiếp tục mua hàng
        </button>
      </div>
    </div>
  );
};

export default connect(state => ({
  isGuest: state.user.isGuest,
  consumer: state.user.consumer,
}))(Payment);
