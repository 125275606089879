import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Form, Select, Input, Checkbox, Modal} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

import * as addressActions from '~actions/address';
import * as addressInfoActions from '~actions/address-info';
import Loading from '~components/page-loader';
import {AddressType} from '~utils/enum';
import {mapAddressData} from '~utils/helper';

import './styles.scss';

const ModalAddress = ({
  createCusAddress,
  city,
  district,
  ward,
  getCity,
  getDistrict,
  getWard,
  onReturn,
  onClose,
  visible,
  isGuest,
  address,
}) => {
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({});

  const curForm = useRef(null);

  useEffect(() => {
    const getCitiInfo = async () => {
      try {
        setLoading(true);
        await getCity();
      } finally {
        setLoading(false);
      }
    };

    getCitiInfo();
  }, []);

  useEffect(() => {
    if (address) {
      setFormState({...address, addressType: AddressType.Home[0]});
    } else {
      setFormState({addressType: AddressType.Home[0]});
    }
  }, [address]);

  useEffect(() => {
    if (!formState.cityId) return;

    const getDistricts = async cityId => {
      try {
        setLoading(true);
        await getDistrict(cityId);
      } finally {
        setLoading(false);
      }
    };

    getDistricts(formState.cityId);
  }, [formState.cityId]);

  useEffect(() => {
    if (!formState.districtId) return;

    const getWards = async districtId => {
      try {
        setLoading(true);
        await getWard(districtId);
      } finally {
        setLoading(false);
      }
    };

    getWards(formState.districtId);
  }, [formState.districtId]);

  const handleFormItemChange = (key, value) => {
    setFormState({...formState, [key]: value});
    curForm.current.setFieldsValue({
      key: value,
    });
  };

  const handleSelectCity = value => {
    setFormState({...formState, cityId: value, districtId: '', wardId: ''});
    curForm.current.setFieldsValue({
      cityId: value,
      districtId: '',
      wardId: '',
    });
  };

  const handleSelectDistrict = value => {
    setFormState({...formState, districtId: value, wardId: ''});
    curForm.current.setFieldsValue({
      districtId: value,
      wardId: '',
    });
  };

  const onFinish = async values => {
    try {
      setLoading(true);
      if (isGuest) {
        if (onReturn) {
          onReturn({
            ...values,
            ward: ward.find(x => x.value === values.wardId)?.label,
            district: district.find(x => x.value === values.districtId)?.label,
            city: city.find(x => x.value === values.cityId)?.label,
          });
        }
      } else {
        const request = {
          ...values,
          id: formState.id,
          addressType: formState.addressType,
          isDefault: formState.isDefault,
        };
        const res = await createCusAddress(request);
        if (!res) return;

        if (onReturn) onReturn();
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = errorInfo => {
    // eslint-disable-next-line no-console
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      visible={visible}
      centered
      title={
        <div className='payment-title'>
          <span className='text-black'>Thêm Địa Chỉ Giao Hàng</span>
          <span onClick={onClose} className='cursor-pointer'>
            <CloseOutlined style={{fontSize: 15}} />
          </span>
        </div>
      }
      okText='Lưu'
      cancelText='Thoát'
      onCancel={onClose}
      onOk={() => curForm.current.submit()}
    >
      <Loading loading={loading} />
      <Form
        name='addressform'
        className='address-form'
        initialValues={formState}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        ref={curForm}
      >
        <Form.Item label='Họ và tên' name='name' rules={[{required: true, message: 'Vui lòng nhập họ và tên!'}]}>
          <Input
            placeholder='Họ và tên'
            value={formState.name}
            onChange={e => handleFormItemChange('name', e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label='Số điện thoai'
          name='mobileNo'
          rules={[{required: true, message: 'Vui lòng nhập số điện thoại!', pattern: new RegExp(/^[0-9]+$/)}]}
        >
          <Input
            placeholder='Số điện thoại'
            value={formState.mobileNo}
            onChange={e => handleFormItemChange('mobileNo', e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label='Tỉnh/Thành phố'
          name='cityId'
          rules={[{required: true, message: 'Vui lòng chọn Tỉnh/Thành phố!'}]}
        >
          <Select
            size='large'
            showSearch
            options={city}
            loading={city.length === 0}
            placeholder='Tỉnh/Thành phố'
            onChange={handleSelectCity}
            filterOption
            optionFilterProp='label'
            value={formState.cityId}
          />
        </Form.Item>
        <Form.Item
          label='Quận/Huyện'
          name='districtId'
          rules={[{required: true, message: 'Vui lòng chọn Quận/Huyện!'}]}
        >
          <Select
            size='large'
            showSearch
            options={district}
            loading={district.length === 0}
            placeholder='Quận/Huyện'
            onChange={handleSelectDistrict}
            filterOption
            optionFilterProp='label'
            value={formState.districtId}
          />
        </Form.Item>
        <Form.Item label='Phường/Xã' name='wardId' rules={[{required: true, message: 'Vui lòng chọn Phường/Xã!'}]}>
          <Select
            size='large'
            showSearch
            options={ward}
            loading={!Array.isArray(ward) || ward.length === 0}
            placeholder='Phường/Xã'
            filterOption
            optionFilterProp='label'
            value={formState.wardId}
            onChange={value => handleFormItemChange('wardId', value)}
          />
        </Form.Item>
        <Form.Item
          label='Địa chỉ'
          name='street'
          rules={[{required: true, message: 'Vui lòng nhập địa chỉ số nhà, đường!'}]}
        >
          <Input
            placeholder='Số nhà, đường'
            value={formState.street}
            onChange={e => handleFormItemChange('street', e.target.value)}
          />
        </Form.Item>
        {!isGuest && (
          <>
            <Form.Item label='Loại địa chỉ' name='addressType'>
              <div className='address-type'>
                {Object.keys(AddressType).map(x => (
                  <div
                    key={x}
                    className={`addr-type-item ${
                      AddressType[x][0] === formState.addressType ? 'selected-addr-item' : ''
                    }`}
                    onClick={() => handleFormItemChange('addressType', AddressType[x][0])}
                  >
                    {AddressType[x][1]}
                  </div>
                ))}
              </div>
            </Form.Item>
            <Form.Item name='isDefault' valuePropName='checked' noStyle>
              <Checkbox
                checked={formState.isDefault}
                onChange={e => handleFormItemChange('isDefault', e.target.checked)}
              >
                Địa chỉ mặc định
              </Checkbox>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default connect(
  state => ({
    isGuest: state.user.isGuest,
    addresses: state.address.addresses,
    city: mapAddressData(state.addressInfo.city || []),
    district: mapAddressData(state.addressInfo.district || []),
    ward: mapAddressData(state.addressInfo.ward || []),
  }),
  dispatch => ({
    createCusAddress: request => dispatch(addressActions.createAddress(request)),
    getCity: () => dispatch(addressInfoActions.getListCity()),
    getDistrict: cityId => dispatch(addressInfoActions.getListDistrict(cityId)),
    getWard: districtId => dispatch(addressInfoActions.getListWard(districtId)),
  })
)(ModalAddress);
