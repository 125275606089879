import React from 'react';

const PromotionIcon = ({fill = '#EF9F84', size = '20'}) => (
  <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.50002 4C5.20334 4 4.91333 4.08798 4.66666 4.2528C4.41999 4.41762 4.22773 4.65189 4.1142 4.92598C4.00067 5.20007 3.97096 5.50167 4.02884 5.79264C4.08672 6.08361 4.22958 6.35088 4.43936 6.56066C4.64914 6.77044 4.91641 6.9133 5.20738 6.97118C5.49835 7.02906 5.79995 6.99935 6.07404 6.88582C6.34813 6.77229 6.5824 6.58003 6.74722 6.33336C6.91204 6.08668 7.00002 5.79667 7.00002 5.5C7.00002 5.10218 6.84198 4.72065 6.56068 4.43934C6.27937 4.15804 5.89784 4 5.50002 4ZM19.12 8.71L10.71 0.290001C10.6166 0.19732 10.5058 0.123995 10.3839 0.0742302C10.2621 0.0244656 10.1316 -0.000759438 10 1.4555e-06H1.00002C0.7348 1.4555e-06 0.480446 0.105358 0.29291 0.292895C0.105374 0.480431 1.67143e-05 0.734785 1.67143e-05 1V10C-0.000744179 10.1316 0.0244809 10.2621 0.0742455 10.3839C0.12401 10.5057 0.197335 10.6166 0.290017 10.71L8.71002 19.12C9.27252 19.6818 10.035 19.9974 10.83 19.9974C11.625 19.9974 12.3875 19.6818 12.95 19.12L19.12 13C19.6818 12.4375 19.9974 11.675 19.9974 10.88C19.9974 10.085 19.6818 9.3225 19.12 8.76V8.71ZM17.71 11.53L11.53 17.7C11.3427 17.8863 11.0892 17.9908 10.825 17.9908C10.5608 17.9908 10.3074 17.8863 10.12 17.7L2.00002 9.59V2H9.59002L17.71 10.12C17.8027 10.2134 17.876 10.3243 17.9258 10.4461C17.9756 10.5679 18.0008 10.6984 18 10.83C17.9989 11.0923 17.8948 11.3437 17.71 11.53Z'
      fill={fill}
    />
  </svg>
);

export default PromotionIcon;
