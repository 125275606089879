import * as React from 'react';

import Layout from '~components/layout';
import Seo from '~components/seo';
import {firebaseAnalytics} from '~utils/firebase';
import Payment from '~containers/checkout/payment';

const CartPage = props => {
  React.useEffect(() => {
    firebaseAnalytics.logEvent('Cart_page_visited');
  }, []);

  return (
    <Layout>
      <Seo title='Thanh toán' />
      <Payment {...props} />
    </Layout>
  );
};

export default CartPage;
