import React, {useEffect, useState} from 'react';
import {Empty} from 'antd';
import {connect} from 'react-redux';
import {navigate} from 'gatsby';
import {useCookies} from 'react-cookie';

import {getActualRentalDate} from '~utils/helper';
import {CartTypes, PaymentType} from '~utils/enum';
import {post} from '~utils/api';
import Loading from '~components/page-loader';
import PaymentInfo from './payment-info';
import PaymentList from './payment-list';
import confirmation from '~utils/modals/confirmation';
import {relogin} from '~actions/user';
import {getMembershipPrice} from '~utils/price-helper';

import './styles.scss';

const isUseMembership = (cartType, consumer, location) => {
  const isMemberShip = cartType === CartTypes.Rent && !!consumer?.membership;

  if (!location?.search) return isMemberShip;

  const searchParams = new URLSearchParams(location.search.slice(1));
  const ignoreMembership = searchParams.get('ignore-membership');
  return !ignoreMembership && isMemberShip;
};

const Payment = ({location, isGuest, consumer, refreshUser}) => {
  const [currentCarts, setCurrentCarts] = useState([]);
  const [cartType, setCartType] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedTransport, setSelectedTransport] = useState(null);
  const [promotion, setPromotion] = useState(null);
  const [paymentType, setPaymentType] = useState(PaymentType.CASH[0]);
  const [orderCreating, setOrderCreating] = useState(false);
  const [cookies, setCookie] = useCookies(['cart-items']);
  const [isToPayNow, setIsToPayNow] = useState(false);
  const [first, setFirst] = useState(false);

  const isMemberShip = isUseMembership(cartType, consumer, location);
  const isAppliedFreeFirstOrder =
    currentCarts.length > 0 &&
    consumer?.membership?.isFreeFirstOrder &&
    !consumer?.membership?.isUsedFreeFirstOrder &&
    currentCarts?.reduce((res, cur) => {
      return res + cur.originalPrice * cur.quantities;
    }, 0) <= consumer?.maxOriginalPrice;
  const isMembershipExpired = consumer?.membership?.expiredDate
    ? new Date(consumer.membership.expiredDate).getTime() < new Date().getDate()
    : false;

  useEffect(() => {
    refreshUser();

    const cartInfoStr = localStorage.getItem('cartInfo');

    if (cartInfoStr) {
      const cartInfo = JSON.parse(cartInfoStr);

      setCartType(cartInfo.cartType);
      setCurrentCarts(cartInfo.carts || []);
      setSelectedTransport(cartInfo.transport);
      setPromotion(cartInfo.promotion);
      setIsToPayNow(cartInfo?.isToPayNow);
    }

    return () => {
      localStorage.removeItem('cartInfo');
    };
  }, [location]);

  useEffect(() => {
    if (isGuest) {
      const guessInfoStr = localStorage.getItem('guessInfo');

      if (guessInfoStr) {
        const guessInfo = JSON.parse(guessInfoStr);
        setSelectedAddress(guessInfo);
      }
    }
  }, [isGuest]);

  useEffect(() => {
    if (!first) {
      setFirst(true);
    } else {
      localStorage.removeItem('cartInfo');
      setCurrentCarts([]);
    }
  }, [isGuest]);

  useEffect(() => {
    if (orderCreating) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [orderCreating]);

  const handleAuthOrder = async () => {
    let request = {
      address: {
        name: selectedAddress.name,
        street: selectedAddress.street,
        wardId: selectedAddress.wardId,
        districtId: selectedAddress.districtId,
        cityId: selectedAddress.cityId,
        mobileNo: selectedAddress.mobileNo,
        ward: selectedAddress.ward,
        district: selectedAddress.district,
        city: selectedAddress.city,
      },
      cartItemIds: currentCarts.map(x => x.id),
      isSale: cartType === CartTypes.Sale,
      promotionCodeId: promotion?.promotionCode?.id,
      deliveryId: selectedTransport.id,
      paymentType,
      useMembershipPackage: false,
    };

    if (isMemberShip) {
      request = {
        ...request,
        useMembershipPackage: true,
        useFreeFirstOrderBenefit: isAppliedFreeFirstOrder,
      };
    }

    const res = await post('/v1/order', request);

    return res;
  };

  const handleCookieOrder = async () => {
    const request = {
      address: {
        name: selectedAddress.name,
        street: selectedAddress.street,
        wardId: selectedAddress.wardId,
        districtId: selectedAddress.districtId,
        cityId: selectedAddress.cityId,
        mobileNo: selectedAddress.mobileNo,
        ward: selectedAddress.ward,
        district: selectedAddress.district,
        city: selectedAddress.city,
      },
      carts: currentCarts.map(x => ({
        productId: x.productId,
        attributes: x.attributes,
        quantities: x.quantities,
        dateFrom: cartType === CartTypes.Sale ? x.dateFrom : getActualRentalDate(new Date(x.dateFrom), 1),
        dateTo: cartType === CartTypes.Sale ? x.dateTo : getActualRentalDate(new Date(x.dateTo), -1),
      })),
      isSale: cartType === CartTypes.Sale,
      promotionCodeId: promotion?.promotionCode?.id,
      deliveryId: selectedTransport.id,
      paymentType,
    };

    const res = await post('/v1/guest-order', request);

    return res;
  };

  const handlePayNowOrder = async () => {
    let request = {
      address: {
        name: selectedAddress.name,
        street: selectedAddress.street,
        wardId: selectedAddress.wardId,
        districtId: selectedAddress.districtId,
        cityId: selectedAddress.cityId,
        mobileNo: selectedAddress.mobileNo,
        ward: selectedAddress.ward,
        district: selectedAddress.district,
        city: selectedAddress.city,
      },
      carts: currentCarts.map(x => ({
        productId: x.productId,
        attributes: x.attributes,
        quantities: x.quantities,
        dateFrom: cartType === CartTypes.Sale ? x.dateFrom : getActualRentalDate(new Date(x.dateFrom), 1),
        dateTo: cartType === CartTypes.Sale ? x.dateTo : getActualRentalDate(new Date(x.dateTo), -1),
      })),
      isSale: cartType === CartTypes.Sale,
      promotionCodeId: promotion?.promotionCode?.id,
      deliveryId: selectedTransport.id,
      paymentType,
      useMembershipPackage: false,
    };

    if (isMemberShip) {
      request = {
        ...request,
        useMembershipPackage: true,
        useFreeFirstOrderBenefit: isAppliedFreeFirstOrder,
      };
    }

    const res = await post('/v1/order-now', request);

    return res;
  };

  const isMembershipValid = () => {
    if (
      !isMemberShip ||
      isAppliedFreeFirstOrder ||
      (!isMembershipExpired &&
        getMembershipPrice(currentCarts, consumer.membership) < consumer.membershipAvailableBalance)
    )
      return true;

    confirmation({
      message:
        'Số tiền trong gói thành viên không đủ để thanh toán. Vui lòng liên hệ Rentzy để nạp thêm tài khoản hoặc tiếp tục thanh toán với không dùng gói thành viên.',
      onOk: () => {
        navigate(`${location.pathname}?ignore-membership=true`, {replace: true});
      },
      onCancel: () => {
        navigate('/home');
      },
      okText: 'Tiếp tục thanh toán',
      cancelText: 'Cancel',
      title: '',
    });
    return false;
  };

  const handleOrder = async () => {
    if (currentCarts.length === 0 || !selectedAddress || !selectedTransport || !isMembershipValid()) return;

    try {
      setOrderCreating(true);

      if (isGuest) {
        const res = await handleCookieOrder();

        if (!res) {
          return;
        }

        if (!isToPayNow) {
          const cookieCarts = cookies['cart-items'] || [];
          const remainCarts = cookieCarts.filter(x => currentCarts.findIndex(y => y.cookieId === x.cookieId) < 0);
          setCookie('cart-items', [...remainCarts]);
        }

        const guessInfoStr = localStorage.getItem('guessInfo');
        const guessInfo = JSON.stringify(selectedAddress);
        guessInfo !== guessInfoStr && localStorage.setItem('guessInfo', JSON.stringify(selectedAddress));
      } else {
        const res = isToPayNow ? await handlePayNowOrder() : await handleAuthOrder();

        if (!res) {
          return;
        }

        if (isMemberShip && isAppliedFreeFirstOrder) {
          await refreshUser();
        }
      }

      localStorage.removeItem('cartInfo');
      navigate('/checkout/order-success');
    } finally {
      setOrderCreating(false);
    }
  };

  const backToHome = () => {
    localStorage.removeItem('cartInfo');
    navigate('/home');
  };

  return (
    <div className='px-90 payment'>
      <div className='text-34 py-30'>Đơn hàng</div>
      <Loading loading={orderCreating} />
      {currentCarts.length <= 0 ? (
        <Empty
          style={{backgroundColor: 'white', padding: '20px 0', margin: 0}}
          description={
            <div>
              <div>Không có sản phẩm để thanh toán!!!</div>
              <button className='btn-continue' onClick={() => backToHome()}>
                Tiếp tục mua hàng
              </button>
            </div>
          }
        />
      ) : (
        <div className='payment-info'>
          <PaymentList currentCarts={currentCarts} cartType={cartType} />
          <PaymentInfo
            currentCarts={currentCarts}
            cartType={cartType}
            address={selectedAddress}
            transport={selectedTransport}
            promotion={promotion}
            isMemberShip={isMemberShip}
            isAppliedFreeFirstOrder={isAppliedFreeFirstOrder}
            isMembershipExpired={isMembershipExpired}
            onAddressChanged={addr => setSelectedAddress(addr)}
            onTransportChanged={trans => setSelectedTransport(trans)}
            onPromotionChanged={pro => setPromotion(pro)}
            setPaymentType={setPaymentType}
            paymentType={paymentType}
            handleOrder={handleOrder}
          />
        </div>
      )}
    </div>
  );
};

export default connect(
  state => ({
    isGuest: state.user.isGuest,
    consumer: state.user.consumer,
  }),
  dispatch => ({
    refreshUser: () => dispatch(relogin()),
  })
)(Payment);
