import React, {useEffect, useState} from 'react';
import {Radio, Modal} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

import {PaymentType} from '~utils/enum';

import './styles.scss';

const PayMethod = ({setPaymentType, paymentType}) => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState(false);

  useEffect(() => {
    const keys = Object.keys(PaymentType);

    if (!paymentType) {
      setPaymentType(PaymentType[keys[0]][0]);
    } else {
      const key = keys.find(x => PaymentType[x][0] === paymentType);
      setType(PaymentType[key][1]);
    }
  }, [paymentType]);

  const openModal = () => setShow(true);

  const handleChange = e => {
    setPaymentType(e.target.value);
    setShow(false);
  };

  return (
    <div className='payment-modal'>
      <div className='payment-title'>
        <div className='text-gray-3 text-13'>Hình thức thanh toán</div>
        {paymentType && (
          <div className='text-gray-3 text-12 cursor-pointer' onClick={openModal}>
            Thay đổi
          </div>
        )}
      </div>
      {type ? (
        <div className='mt-10 font-600'>{type}</div>
      ) : (
        <div className='btn-secondary' onClick={openModal}>
          Chọn Hình Thức Thanh Toán
        </div>
      )}
      <div className='border-line' />
      <Modal
        visible={show}
        footer={null}
        centered
        title={
          <div className='payment-title'>
            <span className='text-black'>Chọn hình thức thanh toán</span>
            <span onClick={() => setShow(false)} className='cursor-pointer'>
              <CloseOutlined style={{fontSize: 15}} />
            </span>
          </div>
        }
      >
        <div className='payment-radio'>
          <Radio.Group onChange={handleChange} value={paymentType}>
            {Object.keys(PaymentType).map(key => (
              <Radio key={key} value={PaymentType[key][0]} disabled={!['CASH', 'BANK_TRANSFER'].includes(key)}>
                {PaymentType[key][1]}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </Modal>
    </div>
  );
};

export default PayMethod;
