// extracted by mini-css-extract-plugin
export var attr = "styles-module--attr--nCtJ9";
export var attrArea = "styles-module--attrArea--w+yc4";
export var cartPrice = "styles-module--cartPrice--ywzkX";
export var color = "styles-module--color--mZwIe";
export var dateTitle = "styles-module--dateTitle--ovSHd";
export var itemAttr = "styles-module--itemAttr--e13Ps";
export var itemPrice = "styles-module--itemPrice--F0tnC";
export var paymentItem = "styles-module--paymentItem--iTDhl";
export var prodDetail = "styles-module--prodDetail--hw0eG";
export var prodName = "styles-module--prodName--dNmsa";