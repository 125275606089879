import React from 'react';
import {connect} from 'react-redux';

import {toVND} from '~utils/helper';
import {getMembershipPrice, getTotalCarts, getTotalDepositCarts} from '~utils/price-helper';
import {CartTypes} from '~utils/enum';
import PayMethod from '../pay-method';
import PriceDetail from '../price-detail';
import Transport from '../transport';
import Address from '../address';
import {getPromotionPrice} from '../constants';
import './styles.scss';

const PaymentInfo = ({
  consumer,
  currentCarts,
  cartType,
  address,
  transport,
  promotion,
  isMemberShip,
  isAppliedFreeFirstOrder,
  isMembershipExpired,
  onAddressChanged,
  onTransportChanged,
  onPromotionChanged,
  handleOrder,
  setPaymentType,
  paymentType,
}) => {
  const getTotalPrice = () => {
    if (cartType === CartTypes.Rent && isMemberShip)
      return isAppliedFreeFirstOrder ? 0 : getMembershipPrice(currentCarts, consumer.membership);

    const totalCarts = getTotalCarts(currentCarts, cartType);
    const curPromotion = getPromotionPrice(totalCarts, promotion);
    return totalCarts + (transport?.transportFee || 0) - curPromotion;
  };

  return (
    <div className='payment-list'>
      <Address address={address} onAddressChanged={onAddressChanged} />
      <Transport cartType={cartType} address={address} transport={transport} onTransportChanged={onTransportChanged} />
      <PriceDetail
        currentCarts={currentCarts}
        cartType={cartType}
        transport={transport}
        promotion={promotion}
        isMemberShip={isMemberShip}
        isMembershipExpired={isMembershipExpired}
        isAppliedFreeFirstOrder={isAppliedFreeFirstOrder}
        onPromotionChanged={onPromotionChanged}
      />
      <PayMethod setPaymentType={setPaymentType} paymentType={paymentType} />
      <div className='final-area'>
        {cartType === CartTypes.Sale ? (
          <div className='price-final'>
            <div className='final-title'>Tổng cộng</div>
            <div className='final-price'>{toVND(getTotalPrice())}</div>
          </div>
        ) : (
          <>
            <div className='price-final'>
              <div className='final-title'>Tổng tiền thuê</div>
              <div className='final-price'>{toVND(getTotalPrice())}</div>
            </div>
            <div className='price-final'>
              <div className='final-title'>Tổng tiền cọc</div>
              <div className='final-price'>{toVND(getTotalDepositCarts(currentCarts, cartType))}</div>
            </div>
          </>
        )}
        <></>
      </div>
      <div className='btn-right' onClick={handleOrder} disabled={currentCarts.length <= 0}>
        Đặt hàng
      </div>
    </div>
  );
};

export default connect(state => ({
  isGuest: state.user.isGuest,
  consumer: state.user.consumer,
}))(PaymentInfo);
