import React from 'react';

import {LazyLoadImage} from 'react-lazy-load-image-component';

import {toVND, buildImageUrl, formatDate} from '~utils/helper';
import {getMembershipPrice, getTotalPriceByItemPrice} from '~utils/price-helper';
import {CartTypes} from '~utils/enum';
import {generatePrice} from '~utils/com-helper';
import {dateFormat} from '~constants/product-detail';

import * as styles from './styles.module.css';

const PaymentItem = ({cartType, product, consumer}) => {
  return (
    <div className={styles.paymentItem}>
      <LazyLoadImage
        src={buildImageUrl(product.productImage, '90x130')}
        alt={product.name}
        title={product.name}
        width={90}
        height={130}
      />
      <div className={styles.prodDetail}>
        <div className={styles.prodName}>{product.productName}</div>
        <div className={styles.cartPrice}>
          {cartType === CartTypes.Sale ? (
            <div className={styles.itemPrice}>Giá bán: {generatePrice(product.originalPrice, product.salePrice)}</div>
          ) : (
            <div>
              <div className={styles.itemPrice}>
                Giá thuê: {generatePrice(product.originalRentalPrice, product.rentalPrice)}
              </div>
              <div className={styles.itemPrice}>
                Giá membership: {product.membershipRentalPrice ? toVND(product.membershipRentalPrice) : 'N/A'}
              </div>
            </div>
          )}
          <div className='font-600'>Số lượng: {product.quantities}</div>
          <div className='text-secondary'>
            {toVND(
              cartType === CartTypes.Sale || !consumer?.membership
                ? getTotalPriceByItemPrice(product, cartType)
                : getMembershipPrice([product], consumer.membership)
            )}
          </div>
        </div>
        <div className={styles.attrArea}>
          <div className={styles.itemAttr}>
            {product.attributes.map(y => (
              <>
                {y.type === 'VALUE_TYPE_COLOR' ? (
                  <div className={styles.color} key={y.value}>
                    <div style={{backgroundColor: y.value}} />
                  </div>
                ) : (
                  <div className={styles.attr} key={y.value}>
                    {y.value}
                  </div>
                )}
              </>
            ))}
            {cartType === CartTypes.Rent && (
              <div className={styles.dateTitle}>
                Thuê từ {formatDate(product.dateFrom, dateFormat)} đến {formatDate(product.dateTo, dateFormat)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentItem;
