import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Modal} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

import {getList} from '~actions/address';

import AddressItem from '../address-item';
import ModalAddress from '../modal-address';
import './styles.scss';

const ModalAddressList = ({visible, onClose, onReturn, addresses, address, getAddresses}) => {
  const [openAddAddress, setOpenAddAddress] = useState(false);

  const handleChangeAddress = item => {
    if (onReturn) onReturn(item);
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      centered
      title={
        <div className='payment-title'>
          <span className='text-black'>Thêm địa chỉ giao hàng</span>
          <span onClick={onClose} className='cursor-pointer'>
            <CloseOutlined style={{fontSize: 15}} />
          </span>
        </div>
      }
    >
      <div className='address-content'>
        {addresses &&
          addresses.map(x => (
            <div key={x.id}>
              <AddressItem
                address={x}
                isCheck={address && x.id === address.id}
                onChangeAddress={() => handleChangeAddress(x)}
              />
              <div className='border-line' />
            </div>
          ))}
        <div className='btn-add' onClick={() => setOpenAddAddress(true)}>
          Thêm địa chỉ
        </div>
        {openAddAddress && (
          <ModalAddress
            visible={openAddAddress}
            onReturn={async () => {
              await getAddresses();
              setOpenAddAddress(false);
            }}
            onClose={() => setOpenAddAddress(false)}
          />
        )}
      </div>
    </Modal>
  );
};

export default connect(
  state => ({
    addresses: state.address.addresses,
  }),
  dispatch => ({
    getAddresses: () => dispatch(getList()),
  })
)(ModalAddressList);
